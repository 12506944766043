@import "~bootstrap/scss/bootstrap.scss";
@import "~slick-carousel/slick/slick";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.min.css";
@import "~pace-js/themes/red/pace-theme-flash.css";
@import "../fonts/Rubik/rubik.css";

@import "./colors";

// ====================================== Typo ==========================================

@font-face {
  font-family: 'Rubik';
  src: local('Rubik Light'), local('Rubik-Light'), url('../fonts/Rubik/Rubiklight.woff2') format('woff2'), url('../fonts/Rubik/Rubiklight.woff') format('woff'), url('../fonts/Rubik/Rubiklight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal; }
@font-face {
  font-family: 'RubikItalick';
  src: local('Rubik Black Italic'), local('Rubik-BlackItalic'), url('../fonts/Rubik/Rubikblackitalic.woff2') format('woff2'), url('../fonts/Rubik/Rubikblackitalic.woff') format('woff'), url('../fonts/Rubik/Rubikblackitalic.ttf') format('truetype');
  font-weight: 900;
  font-style: normal; }
@font-face {
  font-family: 'Rubik';
  src: local('Rubik Black'), local('Rubik-Black'), url('../fonts/Rubik/Rubikblack.woff2') format('woff2'), url('../fonts/Rubik/Rubikblack.woff') format('woff'), url('../fonts/Rubik/Rubikblack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal; }
@font-face {
  font-family: 'Rubik';
  src: local('Rubik Medium'), local('Rubik-Medium'), url('../fonts/Rubik/Rubikmedium.woff2') format('woff2'), url('../fonts/Rubik/Rubikmedium.woff') format('woff'), url('../fonts/Rubik/Rubikmedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal; }
@font-face {
  font-family: 'Rubik';
  src: local('Rubik Light'), local('Rubik-Light'), url('../fonts/Rubik/Rubiklight.woff2') format('woff2'), url('../fonts/Rubik/Rubiklight.woff') format('woff'), url('../fonts/Rubik/Rubiklight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal; }
html {
  font-family: Rubik, serif;
  font-size: 16px;
  background: unset;
  overflow-x: hidden; }

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  font-family: Rubik, serif;
  font-weight: 500;
  letter-spacing: 0.02em;
  margin: 0;
  background: unset;
  color: $red; }

h1 {
  font-size: 4.0625rem; } // 65px
h2 {
  font-size: 2.5rem; // 30px
  font-weight: 500; }
h3 {
  font-size: 1.75rem; } // 28px

h4 {
  font-size: 1.625rem; } // 20px
h5 {
  font-size: 16px; }
h6 {
  font-size: 12px; }
b,
strong {
  font-weight: bold; }

p {
  height: unset; }

// ====================================== Common ========================================

.title {
  text-transform: none; }

html {
    overflow: hidden; }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  overflow-x: hidden; }

.space {
  height: 150px;
  width: 100%; }

section {
  overflow: hidden; }

//ul
//margin: auto
//padding: .5rem 0
//li
//  list-style-type: none

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
input[type=tel]:focus,
input[type=submit]:focus,
input[type=file]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus,
button:focus,
li:focus,
.form-control:focus,
.btn:focus,.btn:active {
  outline: none!important;
  box-shadow: none!important; }

.form-control:not(select) {
  border-radius: 1rem; }

.hoverable, .nav-link {
  transition: $trans;
  cursor: pointer;
  &:hover {
    opacity: .8; } }
.text-red {
  color: $red!important; }
.text-gray {
  color: $dgray!important; }
.text-white {
  color: $white!important; }
.text-black {
  color: $black!important; }
.text-blue {
  color: $blue!important; }
.bg-gray {
  background: $lgray; }
.bg-red {
  background: $red;
  color: $twhite;
  .title {
    h2 {
      color: $twhite; } } }

a, a:hover {
  text-decoration: none;
  color: inherit; }
.underline {
  &::after {
    position: absolute;
    content: '';
    display: block;
    height: 2px;
    background: currentColor;
    width: 0px;
    transition: $trans;
    opacity: .6; }
  &:hover {
    &::after {
      width: 50px; } } }

.grecaptcha-badge {
  z-index: 99; }

.custom-modal {
    display: none;
    &.survey {
      width: 50%;
      overflow: hidden;
      padding: 0;
      border-radius: .5rem;
      .container, .row {
            padding: 0;
            margin: 0; }
      .row {
          height: 600px; }
      label.checkbox {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background: white;
            border: 1px solid #ccc;
            padding: .5rem .8rem;
            input {
                margin-right: .5rem; } } }
    .footer {
        .question-number {
            width: 100%; }
        .question-buttons {
            width: 100%; }
        .progress {
            width: 100%;
            border-radius: 0;
            position: relative;
            .progress-back {
                position: absolute;
                left: 0;
                top: 0;
                height: 4px;
                width: 100%;
                background: #e0e0e0; }
            .progress-line {
                position: absolute;
                left: 0;
                top: 0;
                height: 4px;
                width: 25%;
                background: $red; } } }
    .col-person {
        height: 100%; }
    .person {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: end;
          border-bottom: 1px solid $gray;
          .person-img {
              padding-right: 1rem;
              img {
                  width: 100px;
                  height: 100px;
                  border-radius: 50px; } } } }
// ====================================== Selection color ==================================

::selection {
  color: $white;
  text-shadow: none;
  background-color: $red; }

// ====================================== Bootstrap reset ==================================

.container {
  max-width: 1280px;
 }  //padding: 0
.navbar-nav li:hover>.dropdown-menu {
  display: block; }
.dropdown-menu {
  top: 95%; }
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem; }
#order {
  .form-group {
    margin-bottom: 0; } }

// =================================== Animated mobile menu ================================

.animated-icon1 {
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer; }

.animated-icon1 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out; }

.animated-icon1 span, .animated-icon2 span, .animated-icon3 span {
  background: $black; }

.animated-icon1 span:nth-child(1) {
  top: 0px; }

.animated-icon1 span:nth-child(2) {
  top: 10px; }

.animated-icon1 span:nth-child(3) {
  top: 20px; }

.animated-icon1.open span:nth-child(1) {
  top: 11px;
  transform: rotate(135deg); }

.animated-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px; }

.animated-icon1.open span:nth-child(3) {
  top: 11px;
  transform: rotate(-135deg); }

// ====================================== Social Icons =============================

.social-icons li:first-child {
  margin: 0 1rem 0 0; }
.social-icons li {
  list-style-type: none;
  margin: 0 1rem;
  cursor: pointer;
  .svg-inline--fa {
    border: 1px solid $black;
    border-radius: 15px;
    padding: 5px;
    min-width: 30px;
    min-height: 30px;
    transition: $trans; }
  .fa-youtube {
    &:hover {
      color: $yt; } }
  .fa-facebook {
    &:hover {
      color: $fb; } }
  .fa-instagram {
    &:hover {
      background: $in;
      color: $white; } }
  .fa-vk {
    &:hover {
      background: $vk;
      color: $white; } }
  .fa-skype {
    &:hover {
      color: $sk; } }
  .fa-whatsapp {
    &:hover {
      color: $wa; } }
  .fa-viber {
    &:hover {
      background: $vi;
      color: $white; } } }

// ====================================== Buttons ==================================

.custom-btn {
  background: $red;
  cursor: pointer;
  border-radius: 20px;
  white-space: nowrap;
  max-width: fit-content;
  min-width: 160px;
  line-height: 100%;
  padding: .5rem 1.5rem;
  color: $white;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .8rem;
  margin-right: 2rem;
  &:hover {
    color: $twhite; }
  &.custom-btn2 {
    border: 2px solid $red;
    background: transparent;
    color: $red!important; }
  &.btn-white {
    background: $twhite;
    color: $red; }
  &.btn-gray {
    background: $dgray;
    color: $twhite; } }

// ====================================== Slick slider =============================

.slick-slider {}
/* the slides */
.slick-slide {
  margin: 0 10px; }
/* the parent */
.slick-list {
  margin: 0 -10px; }

// ====================================== Slick dots ===============================

.slick-dots {
  display: flex;
  justify-content: center;
  list-style-type: none;
  li {
    margin: 0;
    button {
      background: $gray;
      color: transparent;
      width: 80px;
      height: 5px;
      transition: $trans;
      opacity: .8;
      &:hover {
        opacity: 1; } } } }
.slick-dots li.slick-active {
  outline: none;
  button {
    background: $red;
    border: unset; } }
@media screen and (max-width: 768px) {
  .slick-dots {
    width: 80%;
    justify-content: center;
    flex-direction: row;
    margin-top: 1rem;
    margin: auto; } }

// ==================================== Slick Arrows ===============================

.slick-arrows {
  display: flex;
  width: 80px;
  justify-content: space-between;
  float: right; }
.slick-arrow {
  background: transparent;
  border: 0;
  padding: 0;
  color: $red;
  &.prev {}
  &.next {
    transform: rotate(180deg); }
  &.slick-disabled {
    cursor: default;
    color: $black; } }
