$trans: all .2s ease-in-out;

// ====================================== Colors ========================================

$black: #575757;
$white: #FAF7F4;
$twhite: #FFF;
$brown: #BD846D;
$red: #AA2222;
$gray: #E8E9EA;
$dgray: #575757;

// -- not using in poetry
$blue: #1F73AE;
$lblue: #3f87c6;
$green: #85C752;
$lgray: #eee;

// -- social colors
$yt: #ff0000;
$fb: #3C5A99;
$in: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
$vk: #4c75a3;
$sk: #00AFF0;
$wa: #25D366;
$vi: #665CAC;
