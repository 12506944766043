@import "common";
// ================================== SECTIONS =====================================

section:not(#carousel) {
  h2 {
    text-transform: uppercase; } }

// ==================================== Header =====================================
//#header
//background: linear-gradient($twhite, $gray)
//color: $black
//border-bottom: 1px solid $gray
//a
//  color: $black

// ==================================== Footer =====================================
//#footer
//background: url("/assets/img/contacts_bg.jpg")
//background-size: cover
//background-repeat: no-repeat
//color: $white
//a
//  color: $white
//.email, .tel, .address
//  .title
//    text-transform: uppercase
//.social-icons li
//  margin: 0 2rem 0 0

// =================================== Main menu ===================================
//#mainmenu
//background: linear-gradient(#b33, #911)
//position: relative
//top: 0
//color: $twhite
//z-index: 999
//display: flex
//align-items: center
//overflow: visible
//a
//  color: $twhite
//  &.nav-link
//    text-transform: uppercase
//    font-weight: bold
//.navbar-nav
//  margin: 0
//  padding: 0
//  .nav-item
//    border-left: 2px ridge $red
@media screen and (max-width: 768px) {
  #mainmenu {
    position: fixed;
    color: $black;
    width: 100%;
    //background: $black
    height: unset;
    box-shadow: 0 0 30px 10px rgba(0,0,0,.5);
    a {
      color: $black; }
    #navbar1 {
      background: $lgray;
      padding: 1rem;
      .separator {
        height: 1px;
        background: rgba(0,0,0,.2);
        margin: 2rem 0; }
      .callback-mob {
        font-weight: bold; } } } }

// ==================================== Carousel ===================================

#carousel {
  position: relative;
  margin-top: 100px;
  z-index: 9;
  h1,h2,h3,h4,h5,h6 {
    color: $twhite!important;
    text-shadow: 5px 5px 10px rgba(0,0,0,.6); }
  .item {
    position: relative;
    overflow: hidden;
    min-height: 800px;
    background-size: cover;
    background-position: center;
    z-index: 9;
    .container {
      min-height: 800px;
      color: $white;
      width: 80%; }
    .title, .content {
      h1, h4 {
        width: 100%;
        text-align: center; } } }
  .slick-dots {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    flex-direction: row; }
  .slick-slide {
    margin: 0; } }
@media screen and (max-width: 768px) {
  #carousel {
    h1 {
      font-size: 2.5rem; }
    h4 {
      font-size: 1.2rem; }
    .item {
      .container {
        width: 100%; } }
    .slick-dots {
      flex-direction: row;
      position: relative;
      margin-top: -6rem;
      z-index: 999;
      top: unset;
      right: unset; } } }

// == breadcrumb
#breadcrumb {
 }  //background: #f9f8f9

.breadcrumb-divider {
  margin: 0 1rem; }

// ===================================== Meter =====================================
#meter {
  background-image: url("/assets/img/meter_bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
.meter-feature {
  //flex-basis: 50%
  padding: 0 1rem 1rem 0;
  width: 50%;
  .img-wrap {
    margin-bottom: 1rem; } }
@media screen and (max-device-width: 768px) {
  .meter-feature {
    width: 100%;
    padding: 0; } }

.lo-price {
  border: 1px solid $blue;
  .price {
      background: $blue;
      color: $white; } }
.lo-price, .hi-price {
  .img-wrap {
    max-height: 630px;
    img {
      max-height: 630px; } } }
#orderRide {
  position: fixed;
  top: 40%;
  right: -75px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 200px;
  background: $red;
  color: $twhite!important;
  z-index: 9999;
  border-radius: 10px 10px 0 0;
  transform: rotateZ(-90deg);
  text-transform: uppercase;
  transition: opacity .2s ease-in-out; }
@media screen and (max-device-width: 768px) {
  #orderRide {
    display: none; } }
// ===================================== Advantages ================================
#advantages {
  padding: 5rem 0;
  width: fit-content;
  margin: 0 auto;
  background: $twhite;
  p {
    margin: 0; }
  .advantage {
    flex-basis: 200px;
    .img-wrap {
      border-radius: 3rem;
      padding: 1rem;
      background: transparent; } } }
@media screen and (max-width: 768px) {
  #advantages {
    margin: 0;
    .advantage {
      margin: 1rem 0;
      .img-wrap {
        margin-right: 0; } } } }

// ===================================== Service ================================
#eo {
  padding: 5rem 0;
  margin: 0 auto;
  background: $twhite;
  background-image: url("/assets/img/eo_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  p {
    margin: 0; }
  .advantage {
    flex-basis: 200px;
    .img-wrap {
      border-radius: 3rem;
      padding: 1rem;
      background: transparent; } }
  .stages-container {
    background: $twhite;
    border: 1px solid $lgray;
    padding: 0;
    .stage {
      .stage-text, .stage-img {
        flex-basis: 50%; }
      &.odd {
        .stage-text {
          order: 1; }
        .stage-img {
          order: 2; } }
      &.even {
        .stage-text {
          order: 2; }
        .stage-img {
          order: 1; } } } } }
@media screen and (max-width: 768px) {
  #eo {
    margin: 0;
    .advantage {
      margin: 1rem 0;
      .img-wrap {
        margin-right: 0; } }
    .stages-container {
      .stage {
        &.odd {
          .stage-text {
            order: 2; }
          .stage-img {
            order: 1; } }
        &.even {
          .stage-text {
            order: 2; }
          .stage-img {
            order: 1; } } } } } }
#serviceOrder {
  background-image: url("/assets/img/order_project.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem 0; }
// ==================================== Catalog ====================================
#catalog {
  .service {
    border-radius: 1rem;
    margin: 1rem;
    background: $twhite;
    border: 1px solid $gray;
    color: $dgray;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    overflow: hidden;
    flex-basis: 20%;
    .svc-name {
      color: $red;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 0;
      font-weight: bold; }
    .svc-content {
      max-width: 100%; }
    .svc-img {
      max-height: 300px;
      img {
        width: 100%;
        height: 100%; } } } }
@media screen and (max-width: 1024px) {
  #catalog {
    .service {
      flex-basis: auto;
      margin: auto;
      margin-bottom: 1.5rem; } } }

.get-price {
  a {
    color: $black; } }
// ==================================== Solutions ==================================
#solutions {
  position: relative;
  background-image: url("/assets/img/svc_bg.jpg");
  .new {
    position: absolute;
    background: white;
    padding: .5rem 2rem .4rem 1.2rem;
    margin: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 100%;
    clip-path: polygon(0 0, 100% 0, 76% 100%, 0% 100%);
    color: $red; }
  .service {
    img {
      height: 500px; } } }

// ==================================== Portfolio ==================================
#services {
  .object {
    flex-basis: 25%;
    padding: 1rem;
    //border: 1px solid $twhite
 } }    //max-width: 25%
@media screen and (max-width: 768px) {
  #services {
    .object {
      flex-basis: 320px; } } }
// ==================================== Feedback ===================================
//#feedback
//color: $white
//background-image: url("/assets/img/feedback-bg.jpg")
//background-position: center
//background-repeat: no-repeat
//background-size: cover
#feedback {
  background: $white;
  .fdb-wrap {
    background-image: url("/assets/img/feedback.jpg");
    background-position: center;
    background-size: cover;
    height: 100%; } }

#order_project {
  background: $white;
  .fdb-wrap {
    background-image: url("/assets/img/order_svc.jpg");
    background-position: center;
    background-size: cover;
    height: 100%; } }

// ==================================== Portfolio ==================================
#portfolio {
  .slick-slide {
    .portfolio-item {
      img {
        border-radius: 1rem; }
      .svc-content {
        .title {
          h5 {
            color: $black; } } } } } }

// == Portfolio page
#portfolioPage {
  .portfolio-list {
    ul {
      width: 100%;
      column-count: 4;
      list-style-type: disc;
      color: $red;
      li {
        padding-right: 1rem;
        a {
          color: $black!important; } } } }
  .portfolioItem {
    display: flex;
    flex-basis: 0 0 21%;
    margin-right: 1.5rem;
    overflow: hidden;
    position: relative;
    img {
      border-radius: 1rem; }
    .portfolioTitleWrap {
      padding: .5rem; } } }
@media screen and (max-width: 1024px) {
  #portfolioPage {
    .portfolio-list {
      ul {
        column-count: 1;
        margin: auto;
        width: inherit;
        margin-bottom: 2rem; } }
    .portfolioItem {
      margin: auto; } } }

// == vacancies page
#vacanciesPage {
  .vacancyItem {
    display: flex;
    flex-direction: column;
    flex: 0 0 21%;
    margin: 1rem auto;
    overflow: hidden;
    border-radius: .5rem;
    border: 1px solid #f0f0f0;
    background: #fcfcfc;
    padding: .5rem;
    position: relative;
    .expierence {
      font-size: small; } } }
@media screen and (max-width: 768px) {
  #vacanciesPage {
    .vacancyItem {
      flex-basis: 100%; } } }
.about-us, .about-us-2 {
  img {
    border-radius: 1rem; } }

// ==================================== Team ===================================
#team {
  .item {
    max-width: 310px;
    margin: auto; } }
.card-body-team {
  .specialization, .title, .title h5, .email, .phon {
    width: fit-content;
    margin: auto;
    text-align: center; } }
.card-team {
  box-shadow: 0 0 5px rgba(0,0,0,.2);
  border-radius: 1rem;
  overflow: hidden;
  transition: box-shadow .2s ease-in-out;
  &:hover {
    box-shadow: 0 0 10px rgba(0,0,0,.3); }
  img {
    width: 310px;
    height: 310px; } }

// ==================================== Video ==================================
#video {
  .slick-slide {
    .video-item {
      position: relative;
      overflow: hidden;
      &:after {
        content: url('/img/playback.png');
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        top: calc(50% - 40px);
        transition: $trans;
        cursor: pointer;
        opacity: 1; }
      &:hover {
        &:after {
          opacity: .8; } }
      .svc-content {
        position: absolute;
        height: 4rem;
        background: rgba(100, 100, 100, 0.5);
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 1rem;
        width: 100%;
        bottom: 0;
        border-radius: 0 0 1rem 1rem;
        .title {
          h5 {
            color: $twhite; } } }
      img {
        border-radius: 1rem; } } } }

// ===================================== Stages ====================================

//#stages
//color: $white
//height: 500px
//background-image: url("/assets/img/stages-bg.jpg")
//background-position: center
//background-repeat: no-repeat
//background-size: cover
//.stage
//  display: flex
//  flex-direction: row
//  align-items: center
//  .stage-digit
//    color: $red
//    font-size: 5rem
//    font-weight: bold
//    margin-right: 2rem
//  .stage-text
//    margin-right: 2rem
//@media screen and (max-width: 768px)
//#stages
//  height: unset
//  .stage
//    flex-direction: column
//    align-items: center
//    justify-content: space-between
//    text-align: center
//    .stage-digit, .stage-text
//      margin-right: 0
// ===================================== About ====================================

#about {
  background: $white;
  .about-wrap {
    background-image: url("/assets/img/order_svc.jpg");
    background-size: cover;
    background-position: center;
    min-height: 500px;
    height: 100%; } }


// ================================= Accordion Tabs ================================
#accordionTabs, #partners {
  box-sizing: border-box;
  h4 {
    font-size: 1.2rem; } }
.tabs {
  display: flex;
  flex-wrap: wrap; }
.tabs {
  label {
    order: 1;
    display: block;
    padding: 1rem 2rem;
    margin-right: 0.2rem;
    cursor: pointer;
    //background: #90CAF9
    font-weight: bold;
    transition: $trans;
    opacity: .7; } }
.tabs {
  .tab {
    order: 99;
    flex-grow: 1;
    width: 100%;
    display: none;
    padding: 1rem;
    background: #fff; } }
.tabs {
  input[type="radio"] {
    display: none; } }
.tabs {
  input[type="radio"] {
    &:checked {
      + label {
        opacity: 1; } } } }
.tabs {
  input[type="radio"]:checked {
    + label {
      + .tab {
        display: block; } } } }
#accordionTabs {
  margin: 1rem 0;
  .tabs {
    .tab {
      .tab-img {
        width: 700px;
        img {
          max-width: 100%; } }
      .tab-icons {
        .icon-wrap {
          flex-basis: 200px;
          .icon {
            border-radius: 3rem;
            padding: 1rem;
            background: $red;
            margin-right: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70px;
            height: 70px;
            margin-bottom: 1rem;
            img {
              max-width: 100%; } } } }
      .tab-bottom {
        .custom-btn {
          font-size: .9rem; } }
      .challenge, .success {
          .chlng-img, .scs-img {
            img {
              max-width: 100%;
              margin: auto; } }
          .chlng-title, .scs-title {
            padding: 1rem 0; } } } } }


@media (max-width: 45em) {
  .tabs {
    .tab {
      order: initial; } }
  .tabs {
    label {
      order: initial; } }
  .tabs {
    label {
      width: 100%;
      margin-right: 0;
      margin-top: 0.2rem; } }
  #accordionTabs {
    .tabs {
      .tab {
        .tab-icons {
          .icon-wrap {
            flex-basis: 100%;
            .icon {
              margin: auto; } } } } } } }



// ====================================== Info =====================================
#info {
  .info-card {
    display: flex;
    flex-direction: row;
    .info-img {
      height: 200px;
      img {
        height: 100%;
        width: auto;
        margin: auto; } }
    .info-content {
      padding: 0 1.2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .info-title {
        padding: 1.2rem 0;
        h4 {
          font-family: "PTSansBold"; } } } } }
@media screen and (max-width: 768px) {
  #info {
    .info-card {
      flex-direction: column;
      overflow: hidden;
      .info-img {
        height: auto;
        width: 100%; } } } }

// == news
#newsPage, #feedbackPage {
  .newsItem {
    display: flex;
    margin: 0 1rem 1rem 0;
    flex-basis: 30%;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    img {
      border-radius: 1rem; }
    &.video-item {
      &:after {
        content: url('/img/playback.png');
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        top: calc(50% - 40px);
        left: calc(50% - 40px);
        cursor: pointer; } }
    .newsTitleWrap {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 50px;
      background: $dgray;
      opacity: .8;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      h5 {
        color: $twhite; } } } }
@media screen and (max-width: 768px) {
  #newsPage, #feedbackPage {
    .newsItem {
      flex-basis: 100%; } } }


// ==================================== Partners ===================================
#partners {
  .slick-slide {
    margin: 0; }
  .slick-list {
    padding-right: 5px; }
  .partners-item, .brands-item {
    height: 120px;
    cursor: pointer;
    padding: 1rem;
    //background: $twhite
    .partners-img, .brands-img {
      border: 1px solid $lgray;
      margin-right: -1px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem;
      border-radius: 1rem;
      background: $twhite;
      transition: $trans;
      img {
        width: 100%;
        filter: grayscale(100%);
        transition: $trans; } }
    &:hover {
      .partners-img, .brands-img {
        box-shadow: 0 0 10px rgba(0,0,0,.3);
        img {
          filter: grayscale(0%); } } } } }
@media screen and (max-width: 768px) {
  #partners {
    .partners-item, .brands-item {
      .partners-img, .brands-img {
        img {
          filter: grayscale(0%);
          width: unset; } } } } }


// ===================================== Footer ====================================
footer {
  background: $black;
  color: $white;
  ul {
    list-style-type: none; } }
.map-container {
  height: 450px;
  //border-radius: 15px
  overflow: hidden; }
.map-row {
  background: url(/img/map_bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

// ================================= scrollTop button ==============================
.scrollTop {
  position: fixed;
  bottom: 5rem;
  right: 5rem;
  border-radius: 2.5rem;
  background: $green;
  width: 3rem;
  height: 3rem;
  color: $white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotateZ(180deg); }
